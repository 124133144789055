<template>
  <div class="auth">
    <div class="wrapper signin">
      <div class="content">
        <div class="logo-signup">
          <!--  <app-signup-logo
            logoImg="/images/logo/logo_new.jpg"
          ></app-signup-logo> -->
        </div>

        <div class="logo">
          <h1>
            <!--<app-i18n code="app.title"></app-i18n>-->
          </h1>
        </div>

        <el-form
          :model="model"
          :rules="rules"
          @submit.prevent.native="doSubmit"
          class="form"
          label-position="left"
          ref="form"
        >
          <el-form-item
            :label="fields.email.label"
            :prop="fields.email.name"
            :required="fields.email.required"
          >
            <el-input
              :placeholder="fields.email.label"
              autocomplete="off"
              ref="focus"
              type="text"
              v-model="model[fields.email.name]"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="fields.password.label"
            :prop="fields.password.name"
            :required="fields.password.required"
          >
            <el-input
              show-password
              :placeholder="fields.password.label"
              autocomplete="off"
              type="password"
              v-model="model[fields.password.name]"
            ></el-input>
          </el-form-item>

          <div>
            <div
              class="w-100"
              style="
                margin-bottom: 16px;
                display: flex;
                align-items: baseline;
                justify-content: space-between;
              "
            >
              <el-checkbox
                v-model="model[fields.rememberMe.name]"
                >{{ fields.rememberMe.label }}</el-checkbox
              >

              <router-link
                :to="{ path: '/auth/forgot-password' }"
              >
                <el-button type="text">
                  <app-i18n
                    code="auth.forgotPassword"
                  ></app-i18n>
                </el-button>
              </router-link>
            </div>
          </div>

          <el-form-item class="form-buttons">
            <el-button
              :loading="loading"
              class="w-100"
              native-type="submit"
              type="primary"
            >
              <app-i18n code="auth.signin"></app-i18n>
            </el-button>
          </el-form-item>
        </el-form>

        <!-- <app-i18n-flags></app-i18n-flags> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/user/user-model';

const { fields } = UserModel;

export default {
  name: 'app-signin-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
        rememberMe: fields.rememberMe.forFormRules(),
      },
      model: {
        rememberMe: true,
      },
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doSigninWithEmailAndPassword:
        'auth/doSigninWithEmailAndPassword',
    }),

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      //convert to lower case
      const email = this.model.email.toLowerCase();
      const password = this.model.password.trim();

      await this.doSigninWithEmailAndPassword({
        email,
        password,
        rememberMe: this.model.rememberMe,
      });
    },
  },
};
</script>

<style></style>
